
import { Component, Vue } from "vue-property-decorator";
import { BASE_API_URL } from "../../../config";
import Axios from "axios";
import { authHeader } from "../../../services/auth";

@Component
export default class DataShow extends Vue {
  public emailList: any = [];
  public jsonData: any = [];
  $router: any;

  public async getEmailTemplates() {
    this.$store.state.tmpcoLoader = true;
    try {
      const response = await Axios.get(
        BASE_API_URL + "super-admin/email-template/getEmailTemplates",
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.emailList = response.data;
        this.jsonData = JSON.stringify(this.emailList);
     //   console.log("45",this.emailList);
     //   console.log("45",this.jsonData);
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public get123(str) {
    str = str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, " ");
    return str;
  }
  async mounted() {
    await this.getEmailTemplates();
  }
}
